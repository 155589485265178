export default class TimeManager {
  private static timeManager: TimeManager;
  public offset: number | undefined;

  private constructor() {
    this.offset = undefined;
  }

  public static instance(): TimeManager {
    if (this.timeManager === undefined) {
      this.timeManager = new TimeManager();
    }

    return this.timeManager;
  }

  sync(serverTimestamp: number) {
    this.offset = serverTimestamp - Date.now().valueOf();
  }

  get currentMSTimestamp(): number {
    return (this.offset ?? 0) + Date.now().valueOf();
  }

  get currentTimestamp(): number {
    return Math.round(((this.offset ?? 0) + Date.now().valueOf()) / 1000.0);
  }
}
