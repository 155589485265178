export default {
  path: "sale-management",
  name: "SaleManagement",
  component: () =>
    import(
      /* webpackChunkName: "sale-management" */ "../views/sale-management/index.vue"
    ),
  redirect: "/sale-management/shops",
  children: [
    {
      path: "shops",
      name: "SaleManagementShops",
      component: () =>
        import(
          /* webpackChunkName: "sale-management-shops" */ "../views/sale-management-shops/index.vue"
        ),
    },
    {
      path: "new-shop",
      name: "SaleManagementNewShop",
      component: () =>
        import(
          /* webpackChunkName: "sale-management-new-shop" */ "../views/sale-management-new-shop/index.vue"
        ),
    },
    {
      path: "recycle-bin",
      name: "SaleManagementRecycleBin",
      component: () =>
        import(
          /* webpackChunkName: "sale-management-recycle-bin" */ "../views/sale-management-recycle-bin/index.vue"
        ),
    },
  ],
};
