const registerInformation = [
  {
    path: "basic",
    name: "ShopRegisterInformationBasic",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-basic" */ "../views/shop-register-information-basic/index.vue"
      ),
  },
  {
    path: "basic-edit",
    name: "ShopRegisterInformationBasicEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-basic-edit" */ "../views/shop-register-information-basic-edit/index.vue"
      ),
  },
  {
    path: "address",
    name: "ShopRegisterInformationAddress",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-address" */ "../views/shop-register-information-address/index.vue"
      ),
  },
  {
    path: "address-edit",
    name: "ShopRegisterInformationAddressEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-address-edit" */ "../views/shop-register-information-address-edit/index.vue"
      ),
  },
  {
    path: "owner",
    name: "ShopRegisterInformationOwner",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-owner" */ "../views/shop-register-information-owner/index.vue"
      ),
  },
  {
    path: "owner-edit",
    name: "ShopRegisterInformationOwnerEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-owner-edit" */ "../views/shop-register-information-owner-edit/index.vue"
      ),
  },
  {
    path: "bank",
    name: "ShopRegisterInformationBank",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-bank" */ "../views/shop-register-information-bank/index.vue"
      ),
  },
  {
    path: "bank-edit",
    name: "ShopRegisterInformationBankEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information-bank-edit" */ "../views/shop-register-information-bank-edit/index.vue"
      ),
  },
  {
    path: "purchase",
    name: "ShopRegisterInformationPurchase",
    component: () =>
      import(
        /* webpackChunkName: "shop-registerInformation-purchase" */ "../views/shop-register-information-purchase/index.vue"
      ),
  },
  {
    path: "purchase-edit",
    name: "ShopRegisterInformationPurchaseEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-registerInformation-purchase-edit" */ "../views/shop-register-information-purchase-edit/index.vue"
      ),
  },
  {
    path: "running-mode",
    name: "ShopRegisterInformationRunningMode",
    component: () =>
      import(
        /* webpackChunkName: "shop-registerInformation-mode" */ "../views/shop-register-information-mode/index.vue"
      ),
  },
  {
    path: "running-mode-edit",
    name: "ShopRegisterInformationRunningModeEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-registerInformation-mode-edit" */ "../views/shop-register-information-mode-edit/index.vue"
      ),
  },
];

const children = [
  {
    path: "status",
    name: "ShopRegisterStatus",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-status" */ "../views/shop-register-status/index.vue"
      ),
  },
  {
    path: "status-edit",
    name: "ShopRegisterStatusEdit",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-status-edit" */ "../views/sale-management-new-shop/index.vue"
      ),
  },
  {
    path: "register-information",
    name: "ShopRegisterInformation",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-information" */ "../views/shop-register-information/index.vue"
      ),
    children: registerInformation,
  },
  {
    path: "discount",
    name: "ShopRegistersSetPlan",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-setPlan" */ "../views/shop-register-setPlan/index.vue"
      ),
  },
  {
    path: "contract",
    name: "ShopRegisterContract",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-contract" */ "../views/shop-register-contract/index.vue"
      ),
  },
  {
    path: "create-account",
    name: "ShopRegisterCreateAccount",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-create" */ "../views/shop-register-create/index.vue"
      ),
  },
  {
    path: "hardware",
    name: "ShopRegisterHardware",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-hardware" */ "../views/shop-register-hardware/index.vue"
      ),
    children: [
      {
        path: "under-application",
        name: "ShopRegisterHardwareUnderApplication",
        component: () =>
          import(
            /* webpackChunkName: "shop-register-hardware-under-application" */ "../views/shop-register-hardware/components/under-application/index.vue"
          ),
      },
      {
        path: "purchased",
        name: "ShopRegisterHardwarePurchased",
        component: () =>
          import(
            /* webpackChunkName: "shop-register-hardware-purchased" */ "../views/shop-register-hardware/components/purchased/index.vue"
          ),
      },
    ],
  },
  {
    path: "new-hardware",
    name: "ShopRegisterNewHardware",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-hardware-new" */ "../views/shop-register-hardware-new-edit/index.vue"
      ),
  },
  {
    path: "edit-hardware/:poNumber",
    name: "ShopRegisterEditHardware",
    component: () =>
      import(
        /* webpackChunkName: "shop-register-hardware-edit" */ "../views/shop-register-hardware-new-edit/index.vue"
      ),
  },
];

export default {
  path: "/shop-register/:projectId",
  name: "ShopRegister",
  component: () =>
    import(
      /* webpackChunkName: "shop-register-shop"*/ "../views/shop-register-shop/index.vue"
    ),
  children: children,
};
