const children = [
  {
    path: "inventory",
    name: "InventoryManagementInventory",
    redirect: "/inventory-management/inventory/inventory",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-inventory" */ "../views/inventory-management-inventory/index.vue"
      ),
    children: [
      {
        path: "inventory",
        name: "InventoryManagementInventoryInventory",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-inventory-inventory" */ "../views/inventory-management-inventory-inventory/index.vue"
          ),
      },
      {
        path: "storage",
        name: "InventoryManagementInventoryStorage",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-inventory-storage" */ "../views/inventory-management-inventory-storage/index.vue"
          ),
      },
      {
        path: "warehousing",
        name: "InventoryManagementInventoryWarehousing",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-inventory-warehousing" */ "../views/inventory-management-inventory-warehousing/index.vue"
          ),
      },
    ],
  },
  {
    path: "purchase",
    name: "InventoryManagementPurchase",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-purchase" */ "../views/inventory-management-purchase/index.vue"
      ),
    redirect: "/inventory-management/purchase/new",
    children: [
      {
        path: "new",
        name: "InventoryManagementPurchaseNew",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-purchase-new" */ "../views/inventory-management-purchase-new/index.vue"
          ),
      },
      {
        path: "processing",
        name: "InventoryManagementPurchaseProcessing",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-purchase-processing" */ "../views/inventory-management-purchase-processing/index.vue"
          ),
      },
      {
        path: "completed",
        name: "InventoryManagementPurchaseCompleted",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-purchase-completed" */ "../views/inventory-management-purchase-completed/index.vue"
          ),
      },
    ],
  },
  {
    path: "purchases/:poNumber",
    name: "InventoryManagementPurchasesPurchaseId",
    component: () =>
      import(
        /* webpackChuckName: "inventory-management-purchases-purchaseId"*/ "../views/inventory-management-new-purchase/index.vue"
      ),
  },
  {
    path: "depreciation",
    name: "InventoryManagementDepreciation",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-depreciation" */ "../views/inventory-management-depreciation/index.vue"
      ),
  },
  {
    path: "new-depreciation",
    name: "InventoryManagementNewDepreciation",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-new-wreck" */ "../views/inventory-management-new-wreck/index.vue"
      ),
  },
  {
    path: "new-storage",
    name: "InventoryManagementNewStorage",
    redirect: "/inventory-management/new-storage/new",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-new-storage" */ "../views/inventory-management-new-storage/index.vue"
      ),
    children: [
      {
        path: "new",
        name: "InventoryManagementNewStorageNew",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-new-storage-new" */ "../views/inventory-management-new-storage-new/index.vue"
          ),
      },
      {
        path: "used",
        name: "InventoryManagementNewStorageUsed",
        component: () =>
          import(
            /* webpackChunkName: "inventory-management-new-storage-used" */ "../views/inventory-management-new-storage-used/index.vue"
          ),
      },
    ],
  },
  {
    path: "new-warehousing",
    name: "InventoryManagementNewWarehousing",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-new-warehousing" */ "../views/inventory-management-new-warehousing/index.vue"
      ),
  },
  {
    path: "warehouse/edit",
    name: "InventoryManagementWarehouseEdit",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-new-warehousing" */ "../views/inventory-management-new-warehousing/index.vue"
      ),
  },
  {
    path: "batch-detail/:batchId",
    name: "InventoryManagementBatchDetail",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-batch-detail" */ "../views/inventory-management-batch-detail/index.vue"
      ),
  },
  {
    path: "device-detail",
    name: "InventoryManagementDeviceDetail",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-device-detail" */ "../views/inventory-management-device-detail/index.vue"
      ),
  },
  {
    path: "storage-detail/:batchId",
    name: "InventoryManagementStorageDetail",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-storage-detail" */ "../views/inventory-management-storage-detail/index.vue"
      ),
  },
  {
    path: "storage/edit",
    name: "InventoryManagementStorageEdit",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-new-storage-new" */ "../views/inventory-management-new-storage-new/index.vue"
      ),
  },
  {
    path: "warehouse-detail/:outNumber",
    name: "InventoryManagementWarehouseDetail",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-warehouse-detail" */ "../views/inventory-management-warehouse-detail/index.vue"
      ),
  },
  {
    path: "po-detail/:poNumber",
    name: "InventoryManagementPODetail",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-po-detail" */ "../views/inventory-management-po-detail/index.vue"
      ),
  },
  {
    path: "purchase-detail/:poNumber",
    name: "InventoryManagementPurchaseDetail",
    component: () =>
      import(
        /* webpackChunkName: "inventory-management-purchase-detail" */ "../views/inventory-management-purchase-detail/index.vue"
      ),
  },
];

export default {
  path: "inventory-management",
  name: "InventoryManagement",
  redirect: "/inventory-management/inventory",
  component: () =>
    import(
      /* webpackChunkName: "inventory-management" */ "../views/inventory-management/index.vue"
    ),
  children: children,
};
