import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './app/index.vue'
import router from './router'

import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Table,
  Tag,
  Select,
  Avatar,
  Dropdown,
  Menu,
  Pagination,
  Radio,
  Popover,
  Upload,
  Row,
  Layout,
  Affix,
  Steps,
  Divider,
  Col,
  Timeline,
  Tooltip,
  Cascader,
  ConfigProvider,
  Spin,
  Switch,
  InputNumber,
  DatePicker,
} from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import { LANGUAGE_TYPE } from './utils/i18n.config'
import LangManager from '@/utils/lang-manager/LangManager'
// svg icon引入
import installIcons from '@/static'
import { bindToGlobal } from './utils/utils'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const i18n = createI18n({
  locale: LangManager.instance().locale,
  fallbackLocale: LANGUAGE_TYPE.ENGLISH,
  legacy: false,
})

bindToGlobal({
  defaultPageSize: 12,
})

const app = createApp(App)

installIcons(app)
app.use(Button)
app.use(Form)
app.use(Input)
app.use(Modal)
app.use(Checkbox)
app.use(Tag)
app.use(Table)
app.use(DatePicker)
app.use(Select)
app.use(Avatar)
app.use(Dropdown)
app.use(Menu)
app.use(Pagination)
app.use(Radio)
app.use(Popover)
app.use(Upload)
app.use(Row)
app.use(Layout)
app.use(Affix)
app.use(Steps)
app.use(Divider)
app.use(Col)
app.use(Timeline)
app.use(Tooltip)
app.use(Cascader)
app.use(ConfigProvider)
app.use(Spin)
app.use(Switch)
app.use(PerfectScrollbar)
app.use(InputNumber)

app.use(router).use(i18n).mount('#app')
