import { UserInfo } from "@/views/login/models";

export default class UserManager {
  private static _userManager: UserManager;

  public static instance(): UserManager {
    if (this._userManager === undefined) {
      this._userManager = new UserManager();
    }

    return this._userManager;
  }

  public email: string;
  public permissions: string[];
  public phoneNumber: string;
  public userId: string;
  public userName: string;

  private constructor() {
    this.email = "";
    this.permissions = [];
    this.phoneNumber = "";
    this.userId = "";
    this.userName = "";
  }

  public updateUser({
    email,
    permissions,
    phoneNumber,
    userId,
    userName,
  }: UserInfo) {
    this.email = email;
    this.permissions = permissions;
    this.phoneNumber = phoneNumber;
    this.userId = userId;
    this.userName = userName;
  }

  public isPermissionValid(permissionCode: string): boolean {
    return this.permissions.some((permission) => permissionCode === permission);
  }
}
