import { h } from 'vue'
import moment from 'moment-timezone'
import { VNode } from '@vue/runtime-core'
import { DeviceModifier } from '@/components/select-device-options/models'

interface CustomRenderTableData<T> {
  text: string | number
  record: T
  index: number
}

const formatTimestamp = (
  timestamp?: string,
  formatStyle = 'MM/DD/YYYY HH:mm',
  timezone?: string,
): string => {
  if (!timestamp || timestamp === '0') {
    return ''
  }

  if (!timezone || timezone.length === 0) {
    return moment(Number(timestamp)).format(formatStyle)
  }
  return moment(Number(timestamp)).tz(timezone).format(formatStyle)
}

// 格式化数字前补足fillLength个0
const formatNum = (num?: number | string, fillLength = 3): string => {
  if (!num) {
    return ''
  }
  return `${num}`.length >= fillLength
    ? `${num}`
    : formatNum('0' + num, fillLength)
}

// 格式化为带$的2位小数
const formatPrice = (price: number): string =>
  price < 0 ? '' : `$${(price / 100).toFixed(2)}`

// 获取对象的某个属性值
const getProperty = <T, K extends keyof T>(obj: T, key: K) => obj[key]

// 格式化要显示的文字，如果可点击的话返回a标签，主要用于表格单元的文本显示
const decorateText = <T>(
  data: CustomRenderTableData<T>,
  formatFunc?: Function,
  clickCallback?: Function,
): string | VNode => {
  if (data.text === '') {
    return data.text
  }

  const str = formatFunc ? formatFunc(data.text) : data.text
  return clickCallback
    ? h(
        'a',
        {
          href: 'javascript:',
          onClick: () => {
            clickCallback(data.record)
          },
        },
        str,
      )
    : str
}

const linkAddress = (addressArray: string[]): string =>
  addressArray
    .map((address) => address.replace(/(^\s*)|(\s*$)/g, ''))
    .filter(
      (addressStr) => addressStr !== undefined && addressStr.trim() !== '',
    )
    .join(', ')

// 设备选项字符串
const deviceOptionSummary = (modifiers?: DeviceModifier[]): string =>
  modifiers
    ? modifiers
        .flatMap((modifier) => modifier.options)
        .map((option) => option.name)
        .join(', ')
    : ''

const bindToGlobal = (obj: any, key = '_const') => {
  if (typeof window[key] === 'undefined') {
    window[key] = {}
  }

  for (const i in obj) {
    window[key][i] = obj[i]
  }
}

const debounce = (fn: Function, delay: number, thisArgs?: object) => {
  let timer: number
  // eslint-disable-next-line
  return (...args: any[]) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(thisArgs ?? window, args)
    }, delay)
  }
}

export {
  CustomRenderTableData,
  formatTimestamp,
  formatNum,
  formatPrice,
  getProperty,
  decorateText,
  linkAddress,
  deviceOptionSummary,
  bindToGlobal,
  debounce,
}
