import { RequestType, Token } from "./utils/types";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CustomSuccessData,
} from "axios";
import TimeManager from "./utils/TimeManager";
import TokenManager from "@/utils/token-manager/TokenManager";

export default class API implements RequestType {
  private static _api: API;

  public static instance() {
    if (API._api === undefined) {
      API._api = new API();
    }
    return API._api;
  }

  private _axiosInstance: AxiosInstance;
  private readonly _headers: { [s: string]: string };
  private _refreshToken: string;
  private _accessToken: string;
  private _requestCount: number;
  private _logout: (() => void) | undefined;
  private _spinning: ((isSpin: boolean) => void) | undefined;

  private constructor() {
    this._headers = {};
    this._refreshToken = "";
    this._accessToken = "";
    this._requestCount = 0;
    this._axiosInstance = axios.create({
      timeout: 60000,
      maxContentLength: 10000,
      baseURL:
        process.env.NODE_ENV === "development" ||
        process.env.VUE_APP_ENV === "dev"
          ? "https://api.yammii.net"
          : "https://dmucbz3fj4pi3.cloudfront.net/",
    });
    this._axiosInstance.interceptors.response.use(
      (response) => {
        const timeManager = TimeManager.instance();
        this.minusRequest();
        if (
          timeManager.offset === undefined &&
          response.headers &&
          response.headers.date
        ) {
          timeManager.sync(Date.parse(response.headers.date));
        }
        if (response.data.status === 200) {
          return response;
        }
        throw new Error(
          `status code is ${response.data.status} and message is ${response.data.message}`
        );
      },
      (error) => {
        this.minusRequest();
        if (
          error.config != undefined &&
          error.response !== undefined &&
          (error.response.data.status === 102 || error.status === 401) &&
          this._logout !== undefined
        ) {
          this._logout();
          TokenManager.instance().removeToken();
        }
      }
    );
    this._axiosInstance.interceptors.request.use((config) => {
      this.addRequest();
      return config;
    });
  }

  private addRequest() {
    this._requestCount += 1;

    if (this._requestCount === 1 && this._spinning !== undefined) {
      this._spinning(true);
    }
  }

  private minusRequest() {
    this._requestCount -= 1;

    if (this._requestCount === 0 && this._spinning !== undefined) {
      this._spinning(false);
    }
  }

  public set logout(logoutFunc: () => void) {
    this._logout = logoutFunc;
  }

  public set spinning(spinningFunc: (isSpin: boolean) => void) {
    this._spinning = spinningFunc;
  }

  public update({ accessToken, refreshToken }: Token) {
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;

    if (accessToken !== "") {
      this._headers.Authorization = `Bearer ${this._accessToken}`;
    } else {
      delete this._headers.Authorization;
    }
  }

  public async delete<T>(
    url: string,
    params?: object,
    config?: AxiosRequestConfig
  ): Promise<CustomSuccessData<T>> {
    const response = await this._axiosInstance.delete(url, {
      ...config,
      params,
      headers: this._headers,
    });
    return response.data;
  }

  public async get<T>(
    url: string,
    params?: object,
    config?: AxiosRequestConfig
  ): Promise<CustomSuccessData<T>> {
    try {
      const response = await this._axiosInstance.get(url, {
        ...config,
        params,
        headers: this._headers,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async post<T>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<CustomSuccessData<T>> {
    try {
      const response = await this._axiosInstance.post(url, data, {
        ...config,
        headers: this._headers,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async put<T>(
    url: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<CustomSuccessData<T>> {
    try {
      const response = await this._axiosInstance.put(url, data, {
        ...config,
        headers: this._headers,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
