<i18n src="./locales/en_US.yaml" locale="en_US" lang="yaml"></i18n>
<i18n src="./locales/zh_CN.yaml" locale="zh_CN" lang="yaml"></i18n>

<template>
  <a-spin :tip="t('text.loading')" size="large" :spinning="spinning">
    <a-config-provider
      :locale="locale"
      :autoInsertSpaceInButton="false"
      :theme="{
        token: {
          colorPrimary: '#ee6611',
        },
      }"
    >
      <template #renderEmpty>
        <div class="no-data-container">
          <svg-icon
            iconClass="no-data"
            style="width: 440px; height: 194px"
          ></svg-icon>
          <span class="font-size-12-0-0-0-04">{{ t('text.noData') }}</span>
        </div>
      </template>
      <router-view />
    </a-config-provider>
  </a-spin>
</template>

<script lang="ts" src="./view-model/index"></script>

<style lang="less">
@import 'styles/index';
</style>
