export default {
  path: "my-information-management",
  name: "MyInformationManagement",
  component: () =>
    import(
      /* webpackChunkName: "my-information-management" */ "../views/my-information-management/index.vue"
    ),
  redirect: "/my-information-management/my-information",
  children: [
    {
      path: "my-information",
      name: "MyInformation",
      component: () =>
        import(
          /* webpackChunkName: "my-information" */ "../views/my-information/index.vue"
        ),
    },
    {
      path: "my-information-edit",
      name: "MyInformationEdit",
      component: () =>
        import(
          /* webpackChunkName: "my-information-edit" */ "../views/my-information-edit/index.vue"
        ),
    },
    {
      path: ":leaderUserId/my-leader-info",
      name: "MyLeaderInformation",
      component: () =>
        import(
          /* webpackChunkName: "my-information-leader-info" */ "../views/my-information-leader-info/index.vue"
        ),
    },
  ],
};
