import cookie from "js-cookie";
import API from "@/utils/network/API";
import { Token } from "@/utils/network/utils/types";

export default class TokenManager {
  private static _tokenManager: TokenManager;

  public static instance(): TokenManager {
    if (this._tokenManager === undefined) {
      this._tokenManager = new TokenManager();
    }

    return this._tokenManager;
  }

  private _accessToken: string;
  private _refreshToken: string;

  private constructor() {
    const accessToken = cookie.get("accessToken") || "";
    const refreshToken = cookie.get("refreshToken") || "";
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    TokenManager.updateAPIToken({ accessToken, refreshToken });
  }

  get accessToken() {
    return this._accessToken;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  private static updateAPIToken({ accessToken, refreshToken }: Token) {
    const api = API.instance();
    api.update({ accessToken, refreshToken });
  }

  public updateToken({ accessToken, refreshToken }: Token) {
    this._accessToken = accessToken;
    this._refreshToken = refreshToken;
    cookie.set("accessToken", accessToken);
    cookie.set("refreshToken", refreshToken);
    TokenManager.updateAPIToken({ accessToken, refreshToken });
  }

  public removeToken() {
    this._accessToken = "";
    this._refreshToken = "";
    cookie.remove("accessToken");
    cookie.remove("refreshToken");
    const api = API.instance();
    api.update({ accessToken: "", refreshToken: "" });
  }
}
