export default {
  path: "human-resources-management",
  name: "HrManagement",
  component: () =>
    import(
      /* webpackChunkName: "human-resources-management" */ "../views/human-resources-management/index.vue"
    ),
  redirect: "/human-resources-management/staffs",
  children: [
    {
      path: "staffs",
      name: "HrManagementStaffs",
      component: () =>
        import(
          /* webpackChunkName: "human-resources-management-staffs" */ "../views/human-resources-management-staffs/index.vue"
        ),
    },
    {
      path: "new-staff",
      name: "HrManagementNewStaff",
      component: () =>
        import(
          /* webpackChunkName: "human-resources-management-new-staff" */ "../views/human-resources-management-new-staff/index.vue"
        ),
    },
    {
      path: ":userId/staffs-detail",
      name: "HrManagementStaffDetail",
      component: () =>
        import(
          /* webpackChunkName: "human-resources-management-staff-detail" */ "../views/human-resources-management-staff-detail/index.vue"
        ),
    },
    {
      path: "staffs-edit",
      name: "HrManagementStaffEdit",
      component: () =>
        import(
          /* webpackChunkName: "human-resources-management-staff-edit" */ "../views/human-resources-management-new-staff/index.vue"
        ),
    },
  ],
};
