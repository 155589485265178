import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "no-data-container" }
const _hoisted_2 = { class: "font-size-12-0-0-0-04" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    tip: _ctx.t('text.loading'),
    size: "large",
    spinning: _ctx.spinning
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_config_provider, {
        locale: _ctx.locale,
        autoInsertSpaceInButton: false,
        theme: {
        token: {
          colorPrimary: '#ee6611',
        },
      }
      }, {
        renderEmpty: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_svg_icon, {
              iconClass: "no-data",
              style: {"width":"440px","height":"194px"}
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('text.noData')), 1)
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale"])
    ]),
    _: 1
  }, 8, ["tip", "spinning"]))
}