const children = [
  {
    path: "web-registration",
    name: "ToDoWebRegistration",
    component: () =>
      import(
        /* webpackChunkName: "to-do-web-registration" */ "../views/summary/components/web-registration/index.vue"
      ),
  },
  {
    path: "backoffice-registration",
    name: "ToDoBackofficeRegistration",
    component: () =>
      import(
        /* webpackChunkName: "to-do-backoffice-registration" */ "../views/summary/components/backoffice-registration/index.vue"
      ),
  },
  {
    path: "business-contract",
    name: "ToDoBusinessContract",
    component: () =>
      import(
        /* webpackChunkName: "to-do-business-contract" */ "../views/summary/components/business-contract/index.vue"
      ),
  },
  {
    path: "payment",
    name: "ToDoPayment",
    component: () =>
      import(
        /* webpackChunkName: "to-do-payment" */ "../views/summary/components/payment/index.vue"
      ),
  },
  {
    path: "merchant",
    name: "ToDoMerchant",
    component: () =>
      import(
        /* webpackChunkName: "to-do-merchant" */ "../views/summary/components/merchant/index.vue"
      ),
  },
  {
    path: "merchant-purchase",
    name: "ToDoMerchantPurchase",
    component: () =>
      import(
        /* webpackChunkName: "to-do-merchant-purchase" */ "../views/summary/components/merchant-purchase/index.vue"
      ),
  },
  {
    path: "yammii-purchase",
    name: "ToDoYammiiPurchase",
    component: () =>
      import(
        /* webpackChunkName: "to-do-yammii-purchase" */ "../views/summary/components/yammii-purchase/index.vue"
      ),
  },
];

export default {
  path: "summary",
  name: "summary",
  component: () =>
    import(/* webpackChunkName: "summary" */ "../views/summary/index.vue"),
  redirect: "/summary/web-registration",
  children: children,
};
