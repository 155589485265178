import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'IconSvg',
  props: {
    iconClass: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const svgClass = computed(() => {
      return props.className ? 'svg-icon ' + props.className : 'svg-icon'
    })

    return {
      svgClass,
      props,
    }
  },
})
