import { defineComponent, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { message } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import enUS from 'ant-design-vue/es/locale/en_US'
import API from '@/utils/network/API'
import { useRouter } from 'vue-router'
import autoLogin from '../utils/auto-login-handler'
import exceptionPaths from '../utils/exception-paths'

export default defineComponent({
  name: 'App',

  setup() {
    const i18n = useI18n()
    const spinning = ref(false)
    let isInitialized = false

    function spin(isSpin: boolean) {
      spinning.value = isSpin
    }

    const router = useRouter()
    const api = API.instance()
    api.spinning = spin
    api.logout = async () => {
      await router.replace('/login')
    }

    router.beforeEach(async (to, from, next) => {
      const fromPath = from.path
      const toPath = to.path
      if (exceptionPaths.some((path) => path === toPath)) {
        next()
      } else {
        if (fromPath === '/' && !isInitialized) {
          isInitialized = true
          try {
            const autoLoginResult = await autoLogin()
            if (autoLoginResult) {
              message.success(i18n.t('message.autoLoginSuccess'))
              next()
            } else {
              next('/login')
            }
          } catch (error) {
            message.error(
              i18n.t('message.autoLoginFailed', [
                (error as { message: string }).message,
              ])
            )
            next('/login')
          }
        } else {
          next()
        }
      }
    })

    const locale = computed(() => {
      return i18n.locale.value === 'zh_CN' ? zhCN : enUS
    })

    return {
      spinning,
      locale,
      t: i18n.t,
    }
  },
})
