const children = [
  {
    path: "summary",
    name: "MerchantManagementSummary",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-summary" */ "../views/merchant-management-summary/index.vue"
      ),
  },
  {
    path: "basic",
    name: "MerchantManagementBasicRoot",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-basic-root" */ "../views/merchant-management-basic-root/index.vue"
      ),
    children: [
      {
        path: "information",
        name: "MerchantManagementBasic",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-basic" */ "../views/merchant-management-basic/index.vue"
          ),
      },
      {
        path: "information-edit",
        name: "MerchantManagementBasicEdit",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-basic-edit" */ "../views/merchant-management-basic-edit/index.vue"
          ),
      },
    ],
  },
  {
    path: "service",
    name: "MerchantManagementServiceRoot",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-service-root" */ "../views/merchant-management-service-root/index.vue"
      ),
    children: [
      {
        path: "information",
        name: "MerchantManagementService",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-service" */ "../views/merchant-management-service/index.vue"
          ),
      },
      {
        path: "information-edit",
        name: "MerchantManagementServiceEdit",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-service-edit" */ "../views/merchant-management-service-edit/index.vue"
          ),
      },
    ],
  },
  {
    path: "gateway",
    name: "MerchantManagementGateway",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-gateway" */ "../views/merchant-management-gateway/index.vue"
      ),
  },
  {
    path: "hardware",
    name: "MerchantManagementHardwareRoot",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-hardware-root" */ "../views/merchant-management-hardware-root/index.vue"
      ),
    children: [
      {
        path: "information",
        name: "MerchantManagementHardware",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-hardware" */ "../views/shop-register-hardware/index.vue"
          ),
        children: [
          {
            path: "under-application",
            name: "MerchantManagementHardwareUnderApplication",
            component: () =>
              import(
                /* webpackChunkName: "merchant-management-hardware-under-application" */ "../views/shop-register-hardware/components/under-application/index.vue"
              ),
          },
          {
            path: "purchased",
            name: "MerchantManagementHardwarePurchased",
            component: () =>
              import(
                /* webpackChunkName: "merchant-management-hardware-purchased" */ "../views/shop-register-hardware/components/purchased/index.vue"
              ),
          },
        ],
      },
      {
        path: "new",
        name: "MerchantManagementNewHardware",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-new-hardware" */ "../views/shop-register-hardware-new-edit/index.vue"
          ),
      },
      {
        path: "edit-hardware/:poNumber",
        name: "MerchantManagementEditHardware",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-edit-hardware" */ "../views/shop-register-hardware-new-edit/index.vue"
          ),
      },
    ],
  },
  {
    path: "invoice",
    name: "MerchantManagementInvoiceRoot",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-invoice-root" */ "../views/merchant-management-invoice-root/index.vue"
      ),
    children: [
      {
        path: "information",
        name: "MerchantManagementInvoice",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-invoice" */ "../views/merchant-management-invoice/index.vue"
          ),
      },
      {
        path: "information-edit",
        name: "MerchantManagementInvoiceEdit",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-invoice-edit" */ "../views/merchant-management-invoice-edit/index.vue"
          ),
      },
      {
        path: "information-detail",
        name: "MerchantManagementInvoiceDetail",
        component: () =>
          import(
            /* webpackChunkName: "merchant-management-invoice-detail" */ "../views/merchant-management-invoice-detail/index.vue"
          ),
      },
      {
        path: "plan",
        name: "MerchantManagementInvoicePlan",
        component: () =>
          import(
            /* webpackChunkName: "shop-register-setPlan" */ "../views/shop-register-setPlan/index.vue"
          ),
      },
    ],
  },
  {
    path: "debug",
    name: "MerchantManagementDebug",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-debug" */ "../views/merchant-management-debug/index.vue"
      ),
  },
  {
    path: "payment-failure",
    name: "MerchantManagementPaymentFailure",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-payment-failure" */ "../views/merchant-management-payment-failure/index.vue"
      ),
  },
  {
    path: "user-files",
    name: "UserFiles",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-user-files" */ "../views/merchant-management-user-files/index.vue"
      ),
  },
  {
    path: "sms-marketing",
    name: "SmsMarkrting",
    component: () =>
      import(
        /* webpackChunkName: "merchant-management-sms-marketing" */ "../views/merchant-management-sms-marketing/index.vue"
      ),
  },
];

export default {
  path: "/merchant-management/:merchantId/:projectId",
  name: "MerchantManagementMerchant",
  redirect: "/merchant-management/:merchantId/:projectId/summary",
  component: () =>
    import(
      /* webpackChunkName: "merchant-management-merchant"*/ "../views/merchant-management-merchant/index.vue"
    ),
  children: children,
};
