export default {
  path: "merchant-management",
  name: "MerchantManagement",
  component: () =>
    import(
      /* webpackChunkName: "merchant-management"*/ "../views/merchant/index.vue"
    ),
  redirect: "/merchant-management/all",
  children: [
    {
      path: "all",
      name: "MerchantManagementAll",
      component: () =>
        import(
          /* webpackChunkName: "merchant-management-all"*/ "../views/merchant-management/index.vue"
        ),
    },
    {
      path: "setting",
      name: "MerchantManagementSetting",
      component: () =>
        import(
          /* webpackChunkName: "merchant-management-setting" */ "../views/merchant-management-setting/index.vue"
        ),
    },
  ],
};
