import cookie from "js-cookie";
import { LANGUAGE_TYPE } from "@/utils/i18n.config";

export default class LangManager {
  private static _langManager: LangManager;

  public static instance(): LangManager {
    if (this._langManager === undefined) {
      this._langManager = new LangManager();
    }

    return this._langManager;
  }

  private _locale: string;

  private constructor() {
    let locale = cookie.get("locale");

    if (locale === undefined) {
      if (navigator.language.toLowerCase().indexOf("zh") !== -1) {
        locale = LANGUAGE_TYPE.CHINESE;
      } else {
        locale = LANGUAGE_TYPE.ENGLISH;
      }

      cookie.set("locale", locale, { expires: 365 });
    }

    this._locale = locale;
  }

  get locale() {
    return this._locale;
  }

  updateLocale(locale: string) {
    this._locale = locale;
    cookie.set("locale", locale, { expires: 365 });
  }
}
