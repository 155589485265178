export default {
  path: "hardware-management",
  name: "HardwareManagement",
  component: () =>
    import(
      /* webpackChunkName: "hardware-management" */ "../views/hardware-management/index.vue"
    ),
  redirect: "/hardware-management/infos",
  children: [
    {
      path: "infos",
      name: "HardwareManagementInfos",
      component: () =>
        import(
          /* webpackChunkName: "hardware-management-infos" */ "../views/hardware-management-infos/index.vue"
        ),
    },
    {
      path: "add",
      name: "HardwareManagementAdd",
      component: () =>
        import(
          /* webpackChunkName: "hardware-management-add" */ "../views/hardware-management-add/index.vue"
        ),
    },
    {
      path: ":hardwareId/detail",
      name: "HardwareManagementDetail",
      component: () =>
        import(
          /* webpackChunkName: "hardware-management-detail" */ "../views/hardware-management-detail/index.vue"
        ),
    },
    {
      path: ":hardwareId/edit",
      name: "HardwareManagementEdit",
      component: () =>
        import(
          /* webpackChunkName: "hardware-management-edit" */ "../views/hardware-management-edit/index.vue"
        ),
    },
  ],
};
