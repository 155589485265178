export default {
  path: "positions",
  name: "Positions",
  component: () =>
    import(/* webpackChunkName: "positions" */ "../views/positions/index.vue"),
  redirect: "/positions/summary",
  children: [
    {
      path: "summary",
      name: "PositionSummary",
      component: () =>
        import(
          /* webpackChunkName: "positions-position-summary" */ "../views/positions-summary/index.vue"
        ),
    },
    {
      path: "new-position",
      name: "AddPosition",
      component: () =>
        import(
          /* webpackChunkName: "positions-new-position" */ "../views/positions-new-position/index.vue"
        ),
    },
    {
      path: ":positionId",
      name: "Position",
      component: () =>
        import(
          /* webpackChuckName: 'positions-position' */ "../views/positions-edit-position/index.vue"
        ),
    },
  ],
};
