export default {
  path: "team-management",
  name: "TeamManagement",
  component: () =>
    import(
      /* webpackChunkName: "team-management" */ "../views/team-management/index.vue"
    ),
  redirect: "/team-management/container",
  children: [
    {
      path: "container",
      name: "TeamManagementContainer",
      component: () =>
        import(
          /* webpackChunkName: "team-management-container" */ "../views/team-management-container/index.vue"
        ),
      children: [
        {
          path: "my-team",
          name: "TeamManagementMyTeamMyTeam",
          component: () =>
            import(
              /* webpackChunkName: "team-management-container-my-team" */ "../views/team-management-container-my-team/index.vue"
            ),
        },
        {
          path: "colleague",
          name: "TeamManagementMyTeamColleague",
          component: () =>
            import(
              /* webpackChunkName: "team-management-container-colleague" */ "../views/team-management-container-colleague/index.vue"
            ),
        },
        {
          path: "leader",
          name: "TeamManagementMyTeamLeader",
          component: () =>
            import(
              /* webpackChunkName: "team-management-container-leader" */ "../views/team-management-container-leader/index.vue"
            ),
        },
      ],
    },
    {
      path: ":userId/user-info",
      name: "TeamManagementUserInfo",
      component: () =>
        import(
          /* webpackChunkName: "team-management-user-info" */ "../views/team-management-user-info/index.vue"
        ),
    },
  ],
};
