export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "zh_CN",
    "resource": {
      "text": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中..."])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无数据"])}
      },
      "message": {
        "requestFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络请求失败，请重试"])},
        "autoLoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动登录成功"])},
        "autoLoginFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["自动登录失败. 原因: ", _interpolate(_list(0))])},
        "fetchDataFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["获取数据失败, 原因: ", _interpolate(_list(0))])}
      }
    }
  })
}