export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "en_US",
    "resource": {
      "text": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])}
      },
      "message": {
        "requestFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad network. Please try again"])},
        "autoLoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto login success"])},
        "autoLoginFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Auto login failed. Reason: ", _interpolate(_list(0))])},
        "fetchDataFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fetch Data Failed. Reason: ", _interpolate(_list(0))])}
      }
    }
  })
}