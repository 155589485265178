import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import positionRoutes from "./position-routes";
import saleManagementRoutes from "./sale-management-routes";
import shopRegisterRoutes from "./shop-register-routes";
import productReleaseRoutes from "./product-relese-routes";
import inventoryManagementRoutes from "./inventory-management-routes";
import merchantManagementMerchantRoutes from "./merchant-management-merchant-routes";
import hrManagementRoutes from "./hr-management-routes";
import myTeamRoutes from "./my-team-routes";
import merchantPOManagementRoutes from "./merchant-po-management-routes";
import summaryRoutes from "./to-do-list-routes";
import hardwareManagementRoutes from "./hardware-management-routes";
import {
  merchantStatisticRoute,
  loginRoute,
  // merchantManagementRoutes,
  commonRoutes,
  newRegistrationRoute,
  passwordRoute,
  chainStoreRoute,
} from "./single-routes";
import merchantManagementRoutes from "./merchant-management-routes";

import myInformation from "./my-information-routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
    redirect: "/summary",
    children: [
      myInformation,
      positionRoutes,
      saleManagementRoutes,
      hrManagementRoutes,
      merchantManagementRoutes,
      productReleaseRoutes,
      inventoryManagementRoutes,
      merchantStatisticRoute,
      summaryRoutes,
      commonRoutes,
      chainStoreRoute,
      myTeamRoutes,
      merchantPOManagementRoutes,
      hardwareManagementRoutes,
    ],
  },
  loginRoute,
  shopRegisterRoutes,
  merchantManagementMerchantRoutes,
  newRegistrationRoute,
  passwordRoute,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundComponent",
    component: () =>
      import(
        /* webpackChunkName: "not-found" */ "../components/not-found/index.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
