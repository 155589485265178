<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="`#icon-${props.iconClass}`"></use>
  </svg>
</template>

<script src="./view-model/index" lang="ts"></script>

<style lang="less" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
