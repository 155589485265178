const children = [
  {
    path: "pos",
    name: "ProductReleasePOS",
    component: () =>
      import(
        /* webpackChunkName: "product-release-pos" */ "../views/product-release-pos/index.vue"
      ),
  },
  // {
  //     path: 'customer',
  //     name: 'ProductReleaseCustomer',
  //     component: () => import(/* webpackChunkName: "product-release-customer" */ '../views/product-release-customer/index.vue'),
  // },
  {
    path: "report",
    name: "ProductReleaseReport",
    component: () =>
      import(
        /* webpackChunkName: "product-release-report" */ "../views/product-release-report/index.vue"
      ),
  },
  {
    path: "billboard",
    name: "ProductReleaseBillboard",
    component: () =>
      import(
        /* webpackChunkName: "product-release-billboard" */ "../views/product-release-billboard/index.vue"
      ),
  },
  {
    path: "serviceStatus",
    name: "ProductReleaseServiceStatus",
    component: () =>
      import(
        /* webpackChunkName: "product-release-service-status" */ "../views/product-release-service-status/index.vue"
      ),
  },
];

export default {
  path: "product-release",
  name: "ProductRelease",
  redirect: "/product-release/pos",
  component: () =>
    import(
      /* webpackChunkName: "product-release" */ "../views/product-release/index.vue"
    ),
  children: children,
};
