export default {
  path: "merchant-po-management",
  name: "MerchantPOManagement",
  component: () =>
    import(
      /* webpackChunkName: "merchant-po-management" */ "../views/merchant-po-management/index.vue"
    ),
  redirect: "/merchant-po-management/merchant-po",
  children: [
    {
      path: "merchant-po",
      name: "MerchantPO",
      component: () =>
        import(
          /* webpackChunkName: "merchant-po-management-merchant-po" */ "../views/merchant-po-management-merchant-po/index.vue"
        ),
    },
    {
      path: ":poNumber/merchant-po-details",
      name: "MerchantPoDetails",
      component: () =>
        import(
          /* webpackChunkName: "merchant-po-management-merchant-po-details" */ "../views/merchant-po-management-merchant-po-details/index.vue"
        ),
    },
  ],
};
