import API from "../../API";
import { LoginModel, LoginResponseModel, UserInfo } from "@/views/login/models";
import TokenManager from "@/utils/token-manager/TokenManager";
const _baseURL = "/sys/admin";
const _loginURL = `${_baseURL}/login`;
const _refreshTokenURL = `${_baseURL}/refresh/token`;
const _logoutURL = `${_baseURL}/logout`;

function login(loginModel: LoginModel) {
  return API.instance().post<LoginResponseModel>(
    _loginURL,
    loginModel.buildRequestModel()
  );
}

function autoLogin() {
  return API.instance().get<UserInfo>("/user/v1.0/superAdmin/currentUser");
}

function refreshLogin(refreshToken: string) {
  return API.instance().get<LoginResponseModel>(_refreshTokenURL, {
    refreshToken,
  });
}

function logout(accessToken: string) {
  TokenManager.instance().removeToken();
  return API.instance().get<object>(_logoutURL, { accessToken });
}

export { login, refreshLogin, logout, autoLogin };
