const merchantStatisticRoute = {
  path: "merchant-statistic",
  name: "MerchantStatistic",
  component: () =>
    import(
      /* webpackChunkName: "merchant-statistic" */ "../views/merchant-statistic/index.vue"
    ),
};

const loginRoute = {
  path: "/login",
  name: "Login",
  component: () =>
    import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
};

const passwordRoute = {
  path: "/password-reset",
  name: "PasswordReset",
  component: () =>
    import(
      /* webpackChunkName: "password-reset" */ "../views/password-reset/index.vue"
    ),
};

// const merchantManagementRoutes = {
//   path: 'merchant-management',
//   name: 'MerchantManagement',
//   component: () => import(/* webpackChunkName: "merchant-management"*/ '../views/merchant-management/index.vue')
// }

const commonRoutes = {
  path: "/common",
  name: "Common",
  component: () =>
    import(/* webpackChunkName: "common" */ "../components/common/index.vue"),
};

const newRegistrationRoute = {
  path: "/new-registration/:registrationDetailId",
  name: "NewRegistration",
  component: () =>
    import(
      /* webpackChunkName: "new-registration" */ "../views/summary/components/new-registration/index.vue"
    ),
};

const chainStoreRoute = {
  path: "/chain-store",
  name: "ChainStore",
  component: () =>
    import(
      /* webpackChunkName: "chain-store" */ "../views/chain-store/index.vue"
    ),
};

export {
  merchantStatisticRoute,
  loginRoute,
  commonRoutes,
  newRegistrationRoute,
  passwordRoute,
  chainStoreRoute,
};
