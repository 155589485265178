import { autoLogin as serverAutoLogin } from "@/utils/network/apis/login/api";
import UserManager from "@/utils/user-manager/UserManager";
import TokenManager from "@/utils/token-manager/TokenManager";

export default async function autoLogin(): Promise<boolean> {
  const tokenManager = TokenManager.instance();
  const refreshToken = tokenManager.refreshToken;

  if (refreshToken !== "") {
    try {
      const { data } = await serverAutoLogin();
      UserManager.instance().updateUser(data);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.resolve(false);
  }
}

// export default async function autoLogin(): Promise<boolean> {
//   const tokenManager = TokenManager.instance()
//   const refreshToken = tokenManager.refreshToken
//
//   if (refreshToken !== '') {
//     try {
//       const { data } = await refreshLogin(refreshToken)
//       tokenManager.updateToken(data)
//       UserManager.instance().updateUser(data.userInfo)
//       return Promise.resolve(true)
//     } catch (error) {
//       return Promise.reject(error)
//     }
//   } else {
//     return Promise.resolve(false)
//   }
// }
